import { FormaPago } from './formaPago';
import { Tarea } from './tarea';
import { EstadoGuiaEntrega } from './estadoGuiaEntrega';
import { C } from '../commons/constantes';
import { TipoDocumento } from './tipoDocumento';

export class Guia {
    //PK
    guiaId : number;

    //Atributos
    nroGuia : string;
    precioTotal : number;
    precioTotalProgramado : number;
    fechaHoraCobro : Date;
    montoCobrado : number;
    detalle : string;
    totalGuias : number;

    //Relaciones
    estadoGuiaEntrega : EstadoGuiaEntrega;
    estadosGuiaEntrega : Array<EstadoGuiaEntrega>;
    formaPago : FormaPago;
    tarea : Tarea;
    tipoDocumento: TipoDocumento;

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;

    //Metodos
    static clonar(objeto : any) : Guia{
        let guia : Guia = new Guia();
        //Propios
        guia.guiaId = objeto.guiaId;
        guia.nroGuia = objeto.nroGuia;
        guia.precioTotal = objeto.precioTotal;
        guia.precioTotalProgramado = objeto.precioTotalProgramado;
        guia.fechaHoraCobro = objeto.fechaHoraCobro;
        guia.montoCobrado = objeto.montoCobrado;
        guia.detalle = objeto.detalle;
        guia.totalGuias = objeto.totalGuias;
        guia.estadoRegistro = objeto.estadoRegistro;
        guia.estadosGuiaEntrega = objeto.estadosGuiaEntrega;
        guia.resultadoRegistro = objeto.resultadoRegistros;        

        //Relaciones
        if(objeto.formaPago != null){
            guia.formaPago = new FormaPago();
            guia.formaPago.copiar(objeto.formaPago);
        }
        if(guia.tarea != null){
            guia.tarea = new Tarea();
            guia.tarea.copiar(objeto.tarea);
        }

        if(objeto.tipoDocumento != null){
            guia.tipoDocumento = new TipoDocumento();
            guia.tipoDocumento.copiar(objeto.tipoDocumento);
        }

        return guia;
    }
    copiar(objeto : any){     
        //Propios
        this.guiaId = objeto.guiaId;
        this.nroGuia = objeto.nroGuia;
        this.precioTotal = objeto.precioTotal;
        this.precioTotalProgramado = objeto.precioTotalProgramado;
        this.fechaHoraCobro = objeto.fechaHoraCobro;
        this.montoCobrado = objeto.montoCobrado;
        this.detalle = objeto.detalle;
        this.totalGuias = objeto.totalGuias;
        this.estadoRegistro = objeto.estadoRegistro;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistros;

        //Relaciones
        if(objeto.estadosGuiaEntrega != null){
            this.estadosGuiaEntrega = objeto.estadosGuiaEntrega;
        }

        if(objeto.estadoGuiaEntrega != null){
            this.estadoGuiaEntrega = new EstadoGuiaEntrega();
            this.estadoGuiaEntrega.copiar(objeto.estadoGuiaEntrega);
        }

        if(objeto.estadoGuiaEntrega != null){
            this.estadosGuiaEntrega = objeto.estadosGuiaEntrega;
            
        }
        
        if(objeto.formaPago != null){
            this.formaPago = new FormaPago();
            this.formaPago.copiar(objeto.formaPago);
        }
        if(objeto.tarea != null){
            this.tarea = new Tarea();
            this.tarea.copiar(objeto.tarea);
        }
        if(objeto.tipoDocumento != null){
            this.tipoDocumento = new TipoDocumento();
            this.tipoDocumento.copiar(objeto.tipoDocumento);
        }
    }

    get noEsEditable() : boolean{
        let result : boolean = false;
        if(this.estadoGuiaEntrega != null && this.estadoGuiaEntrega.estado != null){
            return this.estadoGuiaEntrega.estado > C.GUIA.ESTADO.ENTREGA_PENDIENTE;
        }
        return result;
    }

}
